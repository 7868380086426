import logo from './logo.svg';
import './App.css';
import { Layout, Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './layout/Main';
import { MqttProvider, useMqtt } from './contexts/MqttProvider';
import { useEffect } from 'react';
import mqtt from 'precompiled-mqtt';

var options = {
    host: '5f42cc699894484f93ef78c2efd8a519.s1.eu.hivemq.cloud',
    port: 8883,
    protocol: 'mqtts',
};
function App() {



    return (
        <BrowserRouter>
            <div className="App">
                <Main />
            </div>
        </BrowserRouter>
    );
}

export default App;
