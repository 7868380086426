import { Card } from 'antd';
import axios from 'axios';
import React, { useCallback, useContext, useEffect } from 'react';
import { MqttContext } from '../contexts/MqttProvider';

const Home = () => {
    const { mqttClient, subscribe, unsubscribe } = useContext(MqttContext);
    const [data, setData] = React.useState([]);
    const [newCommand, setNewCommand] = React.useState(true);
    const [command, setCommand] = React.useState('');
    // const [temp, setTemp] = React.useState([]);
    const handleCommand = (e) => {
        // sendCommand();
        data.push(
            <>
                <span className=" text-green-400">raspberrypi:~$</span> <span className="ml-1">{command}</span>
            </>
        );
        setData(data);
        setCommand('');
        // setNewCommand(false);
        mqttClient.publish('12d306ba-c0ff-49d2-9082-cff7853fd9d3/command', JSON.stringify({ method: 'new', command }));
    };
    var timeout = null;
    // useEffect for key press
    useEffect(() => {
        console.log('subscribe to topic');
        subscribe('12d306ba-c0ff-49d2-9082-cff7853fd9d3/response', async (topic, message) => {
            var content = JSON.parse(message);
            clearTimeout(timeout);
            if (content.method === 'stdout' || content.method === 'stderr') {
                timeout = setTimeout(() => setData(data), 500);
                console.log('insert', content.data);
                data.push(
                    <div className="my-2">
                        <pre className={content.method === 'stderr' ? 'text-red-500' : ''}>{content.data}</pre>
                    </div>
                );
            }
        });
        return () => {
            console.log('unsubscribe to topic');
            unsubscribe('12d306ba-c0ff-49d2-9082-cff7853fd9d3/response');
        };
    }, []);

    useEffect(() => {
        const handleKeyPress = (event) => {
            console.log(event.key);
            if (event.key === 'Enter') {
                handleCommand();
            }
        };

        document.addEventListener('keydown', handleKeyPress, false);
        return () => {
            document.removeEventListener('keydown', handleKeyPress, false);
        };
    }, [command, setCommand]);

    return (
        <div className="bg-black p-6 h-full overflow-y-auto">
            <Card className=" bg-gray-800 border-none rounded-lg  ">
                {data.map((item, index) => {
                    return <div className="flex flex-row w-full text-white">{item}</div>;
                })}
                {newCommand && (
                    <div className="flex flex-row text-white">
                        <span className=" text-green-400">raspberrypi:~$</span>{' '}
                        <input
                            type={'text'}
                            value={command}
                            autoFocus
                            onChange={(e) => setCommand(e.target.value)}
                            className="ml-1 w-full bg-transparent border-none outline-none"
                        />
                    </div>
                )}
            </Card>
        </div>
    );
};

export default Home;
